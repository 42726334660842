import { BrowserRouter, Route, Routes } from "react-router-dom";

import './App.css';
import { Main } from "./pages/main/main";
import { AddContact } from "./pages/add_contact/add_contact";
import { AuthProvider } from "react-oidc-context";
import { oidcConfig } from "./utils/helper";
import { Login } from "./pages/login/login";
import { Authorized } from "./pages/authorized/authorized";
import { DeleteAccount } from "./pages/delete_account/delete_account";

function App() {
  return (
    <AuthProvider {...oidcConfig}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/contact/:id" element={<AddContact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/authorized" element={<Authorized />} />
            <Route path="/delete-account" element={<DeleteAccount />} />
          </Routes>
        </BrowserRouter>
      </div>
    </AuthProvider>
  );
}

export default App;
