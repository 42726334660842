
export const PROFILE_URL = 'profile';
export const PUBLIC_PROFILE_URL = 'public-profile';
export const USER_URL = 'user';
export const CONTACTS_URL = 'contacts';

var _API;
var _REDIRECT_URL = '';
var _LOGOUT_REDIRECT_URL = '';
export const RELEASE = 'prod';
if (RELEASE === 'dev') {
  _API = 'http://192.168.1.42:8888/v1/';
  _REDIRECT_URL = "https://dev-geodoc.jarflow.com/authorized/";
} else if (RELEASE === 'prod') {
  _API = 'https://geodoc-api-p.jarflow.com/v1/';
  _REDIRECT_URL = "https://geodoc.jarflow.com/authorized/"
} else {
  _API = "http://localhost:8888/v1/"
  _REDIRECT_URL = "http://localhost:3000/authorized/"
}

if (RELEASE === 'dev') {
  _LOGOUT_REDIRECT_URL = "https://dev-geodoc.jarflow.com/";
} else if (RELEASE === 'prod') {
  _LOGOUT_REDIRECT_URL = "https://geodoc.jarflow.com/"
} else {
  _LOGOUT_REDIRECT_URL = "http://localhost:3000/"
}
export const API_V1 = _API
export const API_V1_AUTH = API_V1 + 'a/';
export const COMMON_V1_AUTH = API_V1 + 'b/';
export const REDIRECT_URL = _REDIRECT_URL;
export const LOGOUT_REDIRECT_URL = _LOGOUT_REDIRECT_URL;

export const UNKNOWN_CONTACT         = 1
export const REQUEST_PENDING_CONTACT = 2
export const KNOWN_CONTACT           = 3