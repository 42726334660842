import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import {REDIRECT_URL} from '../../utils/consts'
import { useNavigate } from "react-router";

export function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(()=>{ 
    if (!auth.isAuthenticated) {
      auth.signinRedirect({redirect_uri: REDIRECT_URL}).then(user =>{
        if (!user || user === null) {
          navigate("/")
        } else {
          if (localStorage.getItem("contact")) {
            navigate(`contact/${localStorage.getItem("contact")})`)
          } else {
            navigate("/")
          }
        }
      }).catch(errr => {
        console.log("AUTH ERR: ", errr)
      })
    }
  }, [auth.isAuthenticated])
  return <></>
}