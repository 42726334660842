import React, { useRef } from "react";
import Container from '@mui/material/Container';
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { deepPurple } from "@mui/material/colors";

export function Main(props) {
  const scrollRef = useRef();
  return (
    <Box sx={{}}>
      <Container maxWidth="lg" style={{ background: "linear-gradient(#091428, #00120B)", height: '100%' }} disableGutters={true}>
        <Stack flexDirection={"row"} style={{ height: 60, background: '#091428', padding: 10 }}>
          <img src={require("../../static/logo192.png")} alt=""/>
          <Stack flexDirection={"row"} style={{ alignItems: 'center', width: '50%',marginLeft: 10 }}>
            <Typography style={{ color: 'white', fontSize: 30 }}>GeoDoc</Typography>
          </Stack>
          <Stack flexDirection={"row"} style={{ alignItems: 'center', width: '50%',marginLeft: 10, height: '100%' }} justifyContent={"end"} alignItems={"center"}>
            <a class="btn-solid-lg secondary"  onClick={() => {
              scrollRef.current.scrollIntoView({behavior: 'smooth'})
            }}>Download</a>
          </Stack>
        </Stack>
        <Stack flexDirection={"row"} style={{ height: 'auto', width: '100%', marginTop: 50}}>
          <Stack style={{ maxHeight: 350, width: '50%' }}>
            <img src={require("../../static/pointer.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
          </Stack>
          <Stack style={{ height: 'auto', width: '50%' }} alignItems={"center"} justifyContent={"start"}>
            <Typography style={{ color: 'white', fontSize: 50, textAlign: 'center' }} variant="h1" gutterBottom>
              Find Your Secret Spots
            </Typography>
            <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
              Transform your everyday tasks into seamless, location-based experiences with GeoDoc, the ultimate tool for creating and managing documents tied to specific geographic locations.
            </Typography>
          </Stack>
        </Stack>
        <Stack flexDirection={"column"} style={{ height: 'auto', width: '100%', marginTop: 50}}>
          <Stack flexDirection={"row"} style={{ height: 350, width: '100%', marginTop: 100 }} justifyContent={"center"} alignItems={"center"}>
            <Stack style={{ height: 'auto', width: '50%' }}>
              <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
                 Whether you're organizing notes, setting up task lists, capturing audio, building video galleries, or storing images, GeoDoc makes it easy to keep everything connected to the places that matter most to you.
              </Typography>
            </Stack>
            <Stack style={{ maxHeight: 350, width: '50%' }}>
              <img src={require("../../static/main_page.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
            </Stack>
          </Stack>
          
          <Stack style={{ height: 'auto', width: '100%', marginTop: 100 }} flexDirection={"row"} alignItems={"center"}>
            <Stack style={{ maxHeight: 350, width: '50%' }}>
              <img src={require("../../static/contacts.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
            </Stack>
            <Stack style={{ height: 'auto', width: '50%' }}>
              <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
                Create and Share Diverse Documents: Craft notes, to-do lists, audio recordings, video galleries, and image collections effortlessly. Share your points of interest with contacts, allowing collaborative access to documents and interaction between users.
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={"row"} style={{ height: 350, width: '100%', marginTop: 100 }} justifyContent={"center"} alignItems={"center"}>
            <Stack style={{ height: 'auto', width: '50%' }}>
              <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
                Location-Based Triggers: Assign documents to specific geo-locations and set a trigger radius. Receive notifications when you arrive at or leave these locations, ensuring you never miss an important reminder.
              </Typography>
            </Stack>
            <Stack style={{ maxHeight: 350, width: '50%' }}>
              <img src={require("../../static/location.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
            </Stack>
          </Stack>
          <Stack style={{ height: 'auto', width: '100%', marginTop: 100 }} flexDirection={"row"} alignItems={"center"}>
            <Stack style={{ maxHeight: 350, width: '50%' }}>
              <img src={require("../../static/share.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
            </Stack>
            <Stack style={{ height: 'auto', width: '50%' }}>
              <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
                Interactive Map View: View, manage, and share your geo-tagged documents on an interactive map. Easily navigate to your points of interest and keep everyone in the loop by sharing specific points with contacts.
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={"row"} style={{ height: 350, width: '100%', marginTop: 100 }} justifyContent={"center"} alignItems={"center"}>
            <Stack style={{ height: 'auto', width: '50%' }}>
              <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
                Collaborative & Flexible Management: Add, edit, or delete location points and their associated documents with just a few taps. Enjoy the flexibility to collaborate, share, and customize the app to suit your needs.
              </Typography>
            </Stack>
            <Stack style={{ maxHeight: 350, width: '50%' }}>
              <img src={require("../../static/task_list.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
            </Stack>
          </Stack>
          <Stack style={{ height: 'auto', width: '100%', marginTop: 100 }} flexDirection={"row"} alignItems={"center"}>
            <Stack style={{ maxHeight: 350, width: '50%' }}>
              <img src={require("../../static/notification.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
            </Stack>
            <Stack style={{ height: 'auto', width: '50%' }}>
              <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
                Stay informed with timely alerts based on your location. GeoDoc keeps you organized and connected, wherever you are.
              </Typography>
            </Stack>
          </Stack>
          <Stack flexDirection={"row"} style={{ height: 350, width: '100%', marginTop: 100 }} justifyContent={"center"} alignItems={"center"}>
            <Stack style={{ height: 'auto', width: '50%' }}>
              <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
                Your data is securely stored, giving you complete control over your documents and locations. GeoDoc respects your privacy, ensuring a safe and reliable experience.
              </Typography>
            </Stack>
            <Stack style={{ maxHeight: 350, width: '50%' }}>
              <img src={require("../../static/data_privacy.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
            </Stack>
          </Stack>
          <Stack style={{ height: 'auto', width: '100%', marginTop: 100 }} flexDirection={"row"} alignItems={"center"}>
            <Stack style={{ maxHeight: 350, width: '50%' }}>
              <img src={require("../../static/collaboration.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
            </Stack>
            <Stack style={{ height: 'auto', width: '50%' }}>
              <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
              GeoDoc takes location-based organization to the next level by enabling collaboration and interactive sharing with friends and colleagues. Whether it’s for coordinating tasks, sharing memories, or keeping others informed, GeoDoc makes every point a shared experience.
              </Typography>
            </Stack>
          </Stack>
          <Stack ref={scrollRef} style={{ width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: 250, marginBlock: 100, height: 350}} flexDirection={"row"}>
            <a href="https://play.google.com/store/apps/details?id=com.geoplannerexpert" target="_blank" class="market-btn apple-btn" role="button" rel="noreferrer">
              <span class="market-button-subtitle">Download on the</span>
              <span class="market-button-title">App Store</span>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.geoplannerexpert" target="_blank" className="market-btn google-btn" role="button" rel="noreferrer">
              <span className="market-button-subtitle">Download on the</span>
              <span className="market-button-title">Google Play</span>
            </a>
          </Stack>
          
        </Stack>
        
      </Container>
    </Box>)
}