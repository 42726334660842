import React from "react";
import Container from '@mui/material/Container';
import { Avatar, Box, Button, Stack, Typography } from "@mui/material";


export function DeleteAccount(props) {
  return (
    <Box sx={{ height: '100vh' }}>
      <Container maxWidth="lg" style={{ background: "linear-gradient(#091428, #00120B)", height: '100%' }} disableGutters={true}>
        <Stack flexDirection={"row"} style={{ height: 60, background: '#091428', padding: 10 }}>
          <img src={require("../../static/logo192.png")} alt=""/>
          <Stack flexDirection={"row"} style={{ alignItems: 'center', width: '50%',marginLeft: 10 }}>
            <Typography style={{ color: 'white', fontSize: 30 }}>GeoDoc</Typography>
          </Stack>
        </Stack>
        <Stack flexDirection={"row"} style={{ height: 'auto', width: '100%', marginTop: 50}}>
          <Stack style={{ maxHeight: 350, width: '50%' }}>
            <img src={require("../../static/delete.jpeg")} alt="" style={{objectFit: 'scale-down', height: 350}}/>
          </Stack>
          <Stack style={{ height: 'auto', width: '50%' }} alignItems={"center"} justifyContent={"center"}>
            <Typography style={{ color: 'white', fontSize: 25, padding: 10 }}  variant="h4">
            To delete all your data, go to the Menu, then select "Settings." Next, choose "Account Settings," then "Delete My Account," and finally press the "Delete My Account" button. All your data (shared documents with contacts, document and location previews, email, unique identifier, and any cloud-synced documents) will be permanently deleted without recovery.
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Box>)
}