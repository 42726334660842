import { Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { deepPurple } from "@mui/material/colors";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate, useParams } from "react-router";
import { API_V1, KNOWN_CONTACT, LOGOUT_REDIRECT_URL, REQUEST_PENDING_CONTACT, UNKNOWN_CONTACT } from "../../utils/consts";
import { addContact, getContactStatus, getPublicProfile, removeContact } from "../../utils/helper";

export function RequestButton(props) {
  const [loading, setLoading] = useState(false);
  switch(props.status.status) {
    case UNKNOWN_CONTACT: {
      return <LoadingButton loading={loading} className="add-btn-contact" variant="contained" style={{borderRadius: 30}} disabled={!props.auth.isAuthenticated} onClick={() => {
        setLoading(true);
        addContact(props.auth.user.access_token, props.dstUserID, props.dstUserName).then(_ => {
          props.getStatus()
          setLoading(false);
        })
      }}>Add contact</LoadingButton>
    }
    case KNOWN_CONTACT: {
      return <LoadingButton className="add-btn-contact" variant="contained" style={{borderRadius: 30}} disabled={!props.auth.isAuthenticated} onClick={() => {
        setLoading(true);
        removeContact(props.auth.user.access_token, props.status.external_id).then(_ => {
          props.getStatus()
          setLoading(false);
        })
      }}>Remove contact</LoadingButton>
    }
    case REQUEST_PENDING_CONTACT: {
      return <LoadingButton className="add-btn-contact" variant="contained" style={{borderRadius: 30}} disabled={!props.auth.isAuthenticated} onClick={() => {
        setLoading(true);
        removeContact(props.auth.user.access_token, props.status.external_id).then(_ => {
          props.getStatus()
          setLoading(false);
        })
      }}>Cancel request</LoadingButton>
    }
    default: {
      return <LoadingButton className="add-btn-contact" variant="contained" style={{borderRadius: 30}} disabled={!props.auth.isAuthenticated} onClick={() => {
        setLoading(true);
        addContact(props.auth.user.access_token, props.dstUserID, props.dstUserName).then(_ => {
          props.getStatus()
          setLoading(false);
        })
      }}>Add contact</LoadingButton>
    }
  }
}

export function AddContact(props) {
  const [profile, setProfile] = useState({})
  const [status, setStatus] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const auth = useAuth();
  let { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth?.user?.access_token) {
      getContactStatus(auth.user.access_token, id).then((resp) => {
        setStatus({...resp})
        console.log("asdasdasd", resp)
      })
    }
    
  }, [auth])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    auth.removeUser().then(_ => {
      auth.signoutRedirect({
        id_token_hint: localStorage.getItem("idToken"),
        post_logout_redirect_uri: LOGOUT_REDIRECT_URL
      });
      auth.clearStaleState();
    });
  };

  useEffect(() => {
    if (id) {
      localStorage.setItem("contact", id);
      getPublicProfile(id).then(publicProfile => {
        
        if (publicProfile.msg) {
          //navigate("/")
        } else {
          setProfile({
            name: publicProfile.user_name,
            abr: publicProfile.abr,
            hash: publicProfile.avatar?.hash,
          })
        }
      }).catch(err => {
        console.log("PUBLIC ERROR", err)
      })
    }
  }, [])

  return (<Box sx={{ height: '100vh' }}>
    <Container maxWidth="lg" style={{ background: "linear-gradient(#091428, #00120B)", height: '100%' }} disableGutters={true}>
      <Stack flexDirection={"row"} style={{height: 60, background: '#091428', padding: 10}}>
        <img src={require("../../static/logo192.png")} alt=""/>
        <Stack flexDirection={"row"} style={{ alignItems: 'center', width: '50%',marginLeft: 10 }}>
          <Typography style={{ color: 'white', fontSize: 30 }}>GeoDoc</Typography>
        </Stack>
        {!auth?.isAuthenticated ? <Stack flexDirection={"row"} style={{justifyContent: 'flex-end', width: '60%', alignItems: 'center'}}>
          <Button variant="contained" className="login-with-google-btn" style={{width: 260, height: 42, color: 'black', backgroundColor: 'white'}} onClick={() => navigate("/login")}>Sign In with Google</Button>
        </Stack> : <Stack flexDirection={"row"} style={{justifyContent: 'flex-end', width: '60%', alignItems: 'center'}}>
          <Typography style={{color: 'white', fontSize: 20, marginRight: 10}}>Hello,</Typography>
          <Typography style={{color: 'white', fontSize: 20, fontWeight: 'bold', marginRight: 30}}>{localStorage.getItem("name")}</Typography>
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar alt={`${localStorage.getItem("abr")}`} src={`${API_V1}b/avatar/${localStorage.getItem("hash")}`} />
            </IconButton>
          </Tooltip>
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleLogout}>Log out</MenuItem>
            </Menu>
          </div>
        </Stack>}
      </Stack>
      <Stack flexDirection={"row"} style={{width: '100%', height: 120, alignItems: 'center', justifyContent: 'center'}}>
        <Typography style={{fontSize: 50, color: 'white'}}>{profile.name}</Typography>
      </Stack>
      <Stack flexDirection={"row"} style={{width: '100%', height: 200, alignItems: 'center', justifyContent: 'center'}}>
        <Avatar sx={{ bgcolor: deepPurple[500], width: 190, height: 190, fontSize: 80}} src={`${API_V1}b/avatar/${profile?.hash}`}>{profile.abr}</Avatar>
      </Stack>
      <Stack flexDirection={"row"} style={{width: '100%', height: 200, alignItems: 'center', justifyContent: 'center'}}>
        <RequestButton status={status} auth={auth} dstUserName={profile.name}  dstUserID={id} getStatus={() => {
          getContactStatus(auth.user.access_token, id).then((resp) => {
            setStatus({...resp})
            console.log("asdasdasd", resp)
          })
        }}/>
      </Stack>
    </Container>
  </Box>)
}