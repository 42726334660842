import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router";
import { getProfile, getPublicProfile } from "../../utils/helper";

export function Authorized(props) {
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.isAuthenticated) {
      if (localStorage.getItem("contact")) {
        const access_token = auth.user?.access_token;
        const id_token = auth.user?.id_token;
        localStorage.setItem("idToken", id_token)
        getProfile(access_token).then(profile => {
          localStorage.setItem("name", profile.user_name)
          setTimeout(() => {
            navigate(`/contact/${localStorage.getItem("contact")}`)
            getProfile(access_token).then(profile => {
              localStorage.setItem("abr", profile.abr)
              localStorage.setItem("hash", profile.avatar.hash)
            })
          }, 300);
        })
      } else {
        navigate('/')
      }
    }
  }, [auth])
  return <></>
}