import { WebStorageStateStore } from "oidc-client-ts";
import { API_V1_AUTH, COMMON_V1_AUTH, CONTACTS_URL, PROFILE_URL, PUBLIC_PROFILE_URL, REDIRECT_URL, RELEASE, USER_URL } from "./consts";

/* export const oidcConfig = {
  authority: "https://api-auth.jarflow.com/realms/geodoc-dev/",
  client_id: "account",
  redirect_uri: REDIRECT_URL,
  scope: 'openid profile email',
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({
    store: localStorage
  }),
}; */
var confAuth;
if (RELEASE && RELEASE !== "prod") {
  confAuth = {
    authority: 'https://api-auth.jarflow.com/realms/geodoc-dev/',
    client_id: 'account',
    redirect_uri: REDIRECT_URL,
    scope: 'openid profile email',
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({
      store: localStorage
    }),
  };
} else {
  confAuth = {
    authority: 'https://api-auth-p.jarflow.com/realms/geodoc/',
    client_id: 'account',
    redirect_uri: REDIRECT_URL,
    scope: 'openid profile email',
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({
      store: localStorage
    }),
  };
}
export var oidcConfig = confAuth;



export function removeContact(accessToken, contactID) {
  const url = `${API_V1_AUTH}${CONTACTS_URL}/${contactID}`;
  return fetch(url, {
    method: 'DELETE',
    headers: {
      AuthToken: accessToken,
      'Content-Type': 'application/json',
    },
  })
    .then(resp => {
      return resp.json();
    })
    .then(jsonData => {
      return jsonData;
    });
}

export function addContact(accessToken, dstUserID, dstUserName) {
  const url = `${API_V1_AUTH}${CONTACTS_URL}`;
  return fetch(url, {
    method: 'POST',
    headers: {
      AuthToken: accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      dst_user: dstUserID,
      dst_user_name: dstUserName
    })
  })
    .then(resp => {
      return resp.json();
    })
    .then(jsonData => {
      return jsonData;
    });
}

export function getContactStatus(accessToken, userID) {
  const url = `${API_V1_AUTH}${USER_URL}/${userID}/contact`;
  return fetch(url, {
    method: 'GET',
    headers: {
      AuthToken: accessToken,
      'Content-Type': 'application/json',
    },
  })
    .then(resp => {
      return resp.json();
    })
    .then(jsonData => {
      return jsonData;
    });
}

export function getPublicProfile(userID) {
  const url = `${COMMON_V1_AUTH}${PUBLIC_PROFILE_URL}/${userID}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(resp => {
      return resp.json();
    })
    .then(jsonData => {
      return jsonData;
    });
}

export function getProfile(accessToken) {
  const url = `${API_V1_AUTH}${PROFILE_URL}`;
  return fetch(url, {
    method: 'GET',
    headers: {
      AuthToken: accessToken,
      'Content-Type': 'application/json',
    },
  })
    .then(resp => {
      return resp.json();
    })
    .then(jsonData => {
      return jsonData;
    });
}